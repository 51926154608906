import React, { Fragment, useEffect, useState } from 'react';
import Print from 'libs/components/Icons/v2/Print';
import { withI18n } from 'libs/support/i18n';
import { bool, object, func } from 'prop-types';

const PrintSection = ({
  orderable,
  translate,
  mobile = false,
}) => {
  const [hoverPrint, setHoverPrint] = useState(false);
  const [printTarget, setPrintTarget] = useState('default');

  useEffect(() => {
    const isGroupOrder = orderable?.groupOrder;
    setPrintTarget(isGroupOrder ? 'default' : 'individual');
  }, [orderable]);


  return (
    <Fragment>
      { orderable.status !== 'newly_placed' &&
        <div className="order-details-header-buttons">
          { mobile ? (
            <a
              className="print-icon"
              onMouseEnter={() => setHoverPrint(true)}
              onMouseLeave={() => setHoverPrint(false)}
            >
              <Print />
            </a>
          )
          :
            <a
              className="btn btn-secondary button-print"
              onMouseEnter={() => setHoverPrint(true)}
              onMouseLeave={() => setHoverPrint(false)}
            >
              <Print />
              Print
            </a>
          }
          { hoverPrint &&
            <div
              className="order-details-button-hover-group"
              onMouseEnter={() => setHoverPrint(true)}
              onMouseLeave={() => setHoverPrint(false)}
            >
              <a
                className="btn btn-secondary button-print-delivery-order"
                href={orderable.deliveryOrderUrl}
                target="_self"
              >
                {translate('ordersShowTermsDownloadDeliveryOrder')}
              </a>
              <a
                className="btn btn-secondary button-print-label"
                href={`/orders/${orderable.uuid}/meal_labels?type=${printTarget}`}
                target="blank"
              >
                {translate('ordersShowTermsPrintLabel')}
              </a>
            </div>
          }
        </div>
      }
    </Fragment>
  );
};

PrintSection.propTypes = {
  orderable: object,
  translate: func,
  mobile: bool,
};


export default withI18n(PrintSection);
