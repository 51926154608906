/* eslint-disable no-useless-catch */
import metaTagsManager from 'libs/middlewares/metaTagsManager';

const options = () => (
  {
    margin: [-5, 0, -5, 0],
    pagebreak: { mode: [] },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  }
);

const processElements = (elements) => {
  const clonedElements = [];

  elements.forEach((el) => {
    const clonedElement = el.cloneNode(true);

    const childrenToRemove = clonedElement.querySelectorAll('.noprint');
    Array.from(childrenToRemove).forEach((child) => {
      // eslint-disable-next-line no-param-reassign
      child.style.opacity = 0;
    });

    clonedElements.push(clonedElement);
  });

  return clonedElements;
};

const injectHtml2Pdf = () => {
  const script = document.createElement('script');
  script.type = 'application/javascript';
  script.src = 'https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js';
  document.head.appendChild(script);
};

const isHtml2PdfAvailable = () => (
  typeof html2pdf === 'function'
);

const getToken = () => (
  metaTagsManager.getCSRFToken()
);

const getHeaders = () => (
  {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': getToken(),
  }
);

const createPdf = async (base64Data) => {
  try {
    const response = await fetch('/blobs', {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ data: base64Data }),
    });

    if (response.ok) {
      const downloadUrl = await response.text();
      // window.open does not work on iOS web view
      window.location.href = downloadUrl;
    } else {
      /* eslint-disable-next-line no-console */
      console.error('Request failed:', response.statusText);
    }
  } catch (error) {
    /* eslint-disable-next-line no-console */
    console.error('Error:', error.message);
  }
};

const renderPdf = async (pages) => {
  /* global html2pdf */
  let worker = html2pdf()
    .set(options())
    .from(pages[0]);

  worker = worker.toPdf();

  if (pages.length > 1) {
    pages.slice(1).forEach((page, _index) => {
      worker = worker
        .get('pdf')
        .then(pdf => {
          pdf.addPage();
        })
        .from(page)
        .toContainer()
        .toCanvas()
        .toPdf();
    });
  }

  return worker.get('pdf');
};

const renderElements = async (elements) => {
  try {
    const pages = processElements(elements);

    const pdf = await renderPdf(pages);

    for (let i = 2; i <= pdf.getNumberOfPages(); i += 1) {
      pdf.deletePage(i);
    }

    const blob = pdf.output('blob');
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        createPdf(reader.result)
          .then(resolve)
          .catch(reject);
      };

      reader.readAsDataURL(blob);
    });
  } catch (error) {
    throw error;
  }
};

export const downloadAsPdf = async (elements, attempt = 1) => {
  if (attempt > 10) {
    throw new Error('Exceeded maximum attempts');
  }

  if (isHtml2PdfAvailable()) {
    try {
      return await renderElements(elements);
    } catch (error) {
      throw error;
    }
  } else {
    injectHtml2Pdf();

    await new Promise(resolve => setTimeout(resolve, 100));

    return downloadAsPdf(elements, attempt + 1);
  }
};
