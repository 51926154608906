/* eslint max-len: 0 */

import * as api from 'libs/middlewares/api';
import * as actionTypes from '../constants/mealLabelConstants';

export function updateLabel(id, attrs) {
  return { type: actionTypes.UPDATE_LABEL, response: { id, attrs } };
}

export function updateDateTimeLabels(attrs) {
  return { type: actionTypes.UPDATE_DATETIME_LABELS, response: { attrs } };
}

export function removeLabel(id) {
  return { type: actionTypes.REMOVE_LABEL, response: { id } };
}

export function addLabel() {
  return { type: actionTypes.ADD_LABEL };
}

export function duplicateLabel(id) {
  return { type: actionTypes.DUPLICATE_LABEL, response: { id } };
}

export function newTemplate() {
  return { type: actionTypes.NEW_TEMPLATE };
}

export function setEditTemplateMode(isEditTemplateMode) {
  return { type: actionTypes.SET_EDIT_TEMPLATE_MODE, response: { isEditTemplateMode } };
}

export function selectTemplate(template) {
  return { type: actionTypes.SELECT_TEMPLATE, response: { template } };
}

export function updateTempTemplate(attrs) {
  return { type: actionTypes.UPDATE_TEMP_TEMPLATE, response: { attrs } };
}

export function saveLabelTemplate(catererId, id, params) {
  return api.save({
    types: [
      actionTypes.SAVING_LABEL_TEMPLATE,
      actionTypes.SAVE_LABEL_TEMPLATE_SUCCESS,
      actionTypes.SAVE_LABEL_TEMPLATE_FAILURE,
    ],
    endpoint: `/vendors/${catererId}/label_templates`,
    params,
    id,
  });
}

export function fetchLabelTemplates(catererId) {
  return api.get({
    types: [
      actionTypes.FETCHING_LABEL_TEMPLATES,
      actionTypes.FETCH_LABEL_TEMPLATES_SUCCESS,
      actionTypes.FETCH_LABEL_TEMPLATES_FAILURE,
    ],
    endpoint: `/vendors/${catererId}/label_templates`,
    params: null,
    id: null,
  });
}

export function deleteLabelTemplate(catererId, id) {
  return api.destroy({
    types: [
      actionTypes.DELETING_LABEL_TEMPLATE,
      actionTypes.DELETE_LABEL_TEMPLATE_SUCCESS,
      actionTypes.DELETE_LABEL_TEMPLATE_FAILURE,
    ],
    endpoint: `/vendors/${catererId}/label_templates`,
    params: null,
    id,
  });
}
