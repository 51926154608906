import React, { useState, useEffect } from 'react';
import { array, func, object, string, bool } from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

import EditPanel from './EditPanel';
import LeftPanel from './LeftPanel';

const Container = styled.div`
  > section {
    background: transparent !important;
  }
`;

const MealLabel = ({
  type,
  addLabel,
  defaultLabels,
  duplicateLabel,
  removeLabel,
  updateLabel,
  selectTemplate,
  newTemplate,
  deleteLabelTemplate,
  fetchLabelTemplates,
  labelTemplates,
  mealPlanId,
  selectedTemplate,
  onlySheet,
  catererId,
}) => {
  const [labels, setLabels] = useState(defaultLabels);
  const [show, setShow] = useState(true);
  const [userAgent, setUserAgent] = useState(null);

  const sortByArrangemets = (label) => [
    label.get(selectedTemplate.settings.sort_by)?.toLowerCase(),
    label.get(selectedTemplate.settings.sort_by === 'meal_name' ? 'name' : 'meal_name')?.toLowerCase(),
  ];

  const toggleSidebar = (e) => {
    e.preventDefault();
    setShow(!show);
  };

  useEffect(() => {
    setLabels(defaultLabels.sortBy(label => sortByArrangemets(label)));
  }, [selectedTemplate]);

  useEffect(() => {
    if (window &&
        window.navigator.userAgentData &&
        !window.navigator.userAgentData.mobile
    ) {
      setUserAgent(window.navigator.userAgent);
    }
  }, []);

  return (
    <Container className={classNames('tw-container-fluid md:tw-overflow-hidden')}>
      <LeftPanel
        {...{
          labels,
          type,
          show,
          userAgent,
          catererId,
          template: selectedTemplate,
        }}
      />

      {
        !onlySheet &&
          <EditPanel
            {
              ...{
                type,
                show,
                addLabel,
                duplicateLabel,
                removeLabel,
                updateLabel,
                selectTemplate,
                newTemplate,
                deleteLabelTemplate,
                fetchLabelTemplates,
                labelTemplates,
                labels,
                mealPlanId,
                selectedTemplate,
                toggleSidebar,
                userAgent,
                catererId,
              }
            }
          />
      }
    </Container>
  );
};

MealLabel.propTypes = {
  type: string.isRequired,
  addLabel: func.isRequired,
  duplicateLabel: func.isRequired,
  removeLabel: func.isRequired,
  updateLabel: func.isRequired,
  selectTemplate: func.isRequired,
  fetchLabelTemplates: func.isRequired,
  deleteLabelTemplate: func.isRequired,
  newTemplate: func.isRequired,
  labelTemplates: array.isRequired,
  defaultLabels: object.isRequired,
  selectedTemplate: object.isRequired,
  mealPlanId: string,
  catererId: string.isRequired,
  onlySheet: bool,
};

export default MealLabel;
