/* eslint max-len: 0, no-global-assign: 0, no-restricted-globals: 0 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { array, func, object, string } from 'prop-types';

import { withI18n } from 'libs/support/i18n';
import { downloadAsPdf } from 'libs/support/downloadAsPdf';
import { TEMPLATES } from 'bundles/meal_labels/constants/sampleTemplates';
import TemplateSettingsContainer from 'bundles/meal_labels/containers/TemplateSettingsContainer';

import TemplateSelect from './TemplateSelect';

const Container = styled.div`
  padding-top: 16px;
  margin-right: 20px;
`;


const buildTemplateOptions = (templates) => [
  ...TEMPLATES,
  ...(templates || []),
  { label: 'Add new template', value: 'new', new: true },
];

/**
 * EditPanel
 */
const EditPanel = ({
  selectTemplate,
  newTemplate,
  deleteLabelTemplate,
  fetchLabelTemplates,
  labelTemplates,
  selectedTemplate,
  catererId,
  translate,
}) => {
  useEffect(() => {
    fetchLabelTemplates(catererId);
  }, []);
  const [downloading, setDownloading] = useState(false);

  const handleOnTemplateChange = (template) => {
    if (template.new) { newTemplate(); } else { selectTemplate(template); }
  };

  const handleOnDeleteLabelTemplate = (id) => {
    deleteLabelTemplate(catererId, id);
  };

  const handleOnPrint = (_) => {
    window.print();
    return false;
  };

  const handleOnDownload = (_) => {
    const startAt = new Date();
    setDownloading(true);
    downloadAsPdf(document.querySelectorAll('.printable-area')).then(() => {
      const timeDiff = new Date() - startAt;
      if (timeDiff < 1000) {
        setTimeout(() => { setDownloading(false); }, 1000 - timeDiff);
      } else {
        setDownloading(false);
      }
    });
    return false;
  };

  return (
    <Container id="edit-panel" className="container noprint">
      <div className="d-flex justify-content-between">
        <div>
          <h5>
            { translate('mealLabelsEditPanelTitle') }
          </h5>
        </div>
        <div>
          <button
            className="ml-2 btn btn-md btn-primary"
            onClick={handleOnPrint}
          >
            { translate('buttonsPrint') }
          </button>
          <button
            className="ml-2 btn btn-md btn-primary"
            onClick={handleOnDownload}
          >
            { downloading ? translate('buttonsDownloading') : translate('buttonsDownload') }
          </button>
        </div>
      </div>

      <hr />

      <div className="row mb-2">
        <div className="col-md-12">
          <div className="form-group row">
            <label className="col-sm-2 col-form-label">
              { translate('mealLabelsEditPanelFieldsTemplate') }
              <span className="label-required"> * </span>
            </label>

            <div className="col-sm-10">
              <TemplateSelect
                id="template-select"
                value={selectedTemplate}
                options={buildTemplateOptions(labelTemplates)}
                onChange={handleOnTemplateChange}
                onDelete={handleOnDeleteLabelTemplate}
              />
            </div>
          </div>
        </div>
      </div>

      <TemplateSettingsContainer />
    </Container>
  );
};

EditPanel.propTypes = {
  selectTemplate: func.isRequired,
  newTemplate: func.isRequired,
  fetchLabelTemplates: func.isRequired,
  labelTemplates: array.isRequired,
  selectedTemplate: object.isRequired,
  translate: func.isRequired,
  deleteLabelTemplate: func,
  catererId: string,
};

export default withI18n(EditPanel);
