/* eslint max-len: 0, no-global-assign: 0, no-restricted-globals: 0 */

import React, { useState } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { PlusIcon, ThreeDotsIcon } from 'libs/components/Icons';
import { notBlank } from 'libs/support/string';
import { bool, object, string } from 'prop-types';

const CustomOption = (props) => {
  const {
    children,
    className,
    cx,
    data,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    selectProps,
  } = props;

  const [showDropdown, setShowDropdown] = useState(false);

  const handleOnShowDropdownClick = (evt) => {
    evt.stopPropagation();

    setShowDropdown(prev => !prev);
  };

  const handleOnDeleteClick = (evt) => {
    evt.stopPropagation();

    if (selectProps.onDelete) selectProps.onDelete(data.uuid);
    setShowDropdown(false);
  };

  return (
    <div
      id={`option-${data.value}`}
      style={getStyles('option', props)}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className,
      )}
      ref={innerRef}
      aria-disabled={isDisabled}
      {...innerProps}
    >
      <div className="tw-flex tw-items-center tw-gap-2">
        {
          data.new ?
            <a href="#" data-turbolinks="false" className="tw-flex tw-items-center tw-gap-2 hover:!tw-no-underline">
              <PlusIcon />
              <span>{children}</span>
            </a> :
            <span>{children}</span>
        }

        {
          !data.new && notBlank(data.uuid) &&
            <div className="dropdown tw-mt-2">
              <span
                id={`delete-template-${data.uuid}`}
                style={{ cursor: 'pointer' }}
                onClick={handleOnShowDropdownClick}
                role="presentation"
              >
                <ThreeDotsIcon />
              </span>

              <div
                className={classNames('dropdown-menu dropdown-menu-right', { show: showDropdown })}
                style={{ marginTop: '-0.5rem', cursor: 'pointer' }}
              >
                <div
                  className="dropdown-item"
                  onClick={handleOnDeleteClick}
                  role="presentation"
                >
                  Delete
                </div>
              </div>
            </div>
        }
      </div>
    </div>
  );
};

CustomOption.propTypes = {
  children: object,
  className: string,
  cx: object,
  data: object,
  getStyles: object,
  isDisabled: bool,
  isFocused: bool,
  isSelected: bool,
  innerRef: object,
  innerProps: object,
  selectProps: object,
};

const TemplateSelect = (props) => (<Select {...props} components={{ Option: CustomOption }} />);
export default TemplateSelect;
