/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint max-len: 0, no-global-assign: 0, no-restricted-globals: 0, no-dupe-keys: 0 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { array, bool, func, object, string } from 'prop-types';

import { downloadAsPdf } from 'libs/support/downloadAsPdf';
import { withI18n } from 'libs/support/i18n';
import { notBlank } from 'libs/support/string';
import TemplateSettingsContainer from 'bundles/meal_labels/containers/TemplateSettingsContainer';
import { TEMPLATES } from 'bundles/meal_labels/constants/sampleTemplates';

import Gear from 'libs/components/Icons/Gear';
import Labels from './Labels';
import TemplateSelect from './TemplateSelect';

const Container = styled.div`
  padding-top: 16px;
`;

const BackContainer = styled.div`
  display: block;
  margin-bottom: 20px;

  @media (min-width: ${props => props.theme.breakPoints.xs}) {
    display: none;
  }
`;

const buildTemplateOptions = (templates) => [
  ...TEMPLATES,
  ...(templates || []),
  { label: 'Add new template', value: 'new', new: true },
];

/**
 * EditPanel
 */
const EditPanel = ({
  type,
  show,
  addLabel,
  duplicateLabel,
  removeLabel,
  updateLabel,
  selectTemplate,
  newTemplate,
  deleteLabelTemplate,
  fetchLabelTemplates,
  labelTemplates,
  labels,
  selectedTemplate,
  catererId,
  translate,
  toggleSidebar,
  userAgent,
}) => {
  const [downloading, setDownloading] = useState(false);
  const [datetime, setDateTime] = useState();

  const handleOnTemplateChange = (template) => {
    if (template.new) { newTemplate(); } else { selectTemplate(template); }
  };

  const handleOnDeleteLabelTemplate = (id) => {
    deleteLabelTemplate(catererId, id);
  };

  const handleOnPrint = (e) => {
    toggleSidebar(e, false);
    setTimeout(window.print, 300);
    return false;
  };

  const handleOnDownload = (e) => {
    window.scrollTo({ top: 0 });
    toggleSidebar(e, false);

    const startAt = new Date();
    setDownloading(true);
    downloadAsPdf(document.querySelectorAll('.printable-area')).then(() => {
      const timeDiff = new Date() - startAt;
      if (timeDiff < 1000) {
        setTimeout(() => { setDownloading(false); }, 1000 - timeDiff);
      } else {
        setDownloading(false);
      }
    });
    return false;
  };

  useEffect(() => {
    fetchLabelTemplates(catererId);
  }, []);

  useEffect(() => {
    if (!labels.size) return;
    const date = dayjs(labels.last().get('schedule_at'));
    setDateTime(date);
  }, [labels]);

  return (
    <Container
      id="edit-panel"
      className={classNames('noprint tw-bg-white tw-transform tw-transition-all', {
        'tw-border-r-[1px] tw-border-gray-200 tw-text-sm': true,
        'tw-fixed tw-left-0 tw-top-0 tw-p-4': true,
        '!tw-width-full !tw-min-w-full !tw-max-w-full': true,
        'md:!tw-width-[480px] md:!tw-min-w-[480px] md:!tw-max-w-[480px]': true,
        'tw-translate-x-0': show,
        '-tw-translate-x-full': !show,
      })}
    >
      <div
        className="tw-absolute tw-top-0 -tw-right-[4rem]"
        role="presentation"
        onClick={toggleSidebar}
      >
        <div className={classNames('tw-bg-white !tw-h-[70px] !tw-w-[70px]', {
          'tw-flex tw-justify-center tw-items-center': true,
          'tw-border tw-border-gray-200 tw-border-l-0': true,
        })}
        >
          <Gear />
        </div>
      </div>

      {!notBlank(userAgent) &&
        <BackContainer>
          <a href="#" data-turbolinks="false" onClick={_ => window.history.go(-1)}>
            <span className="glyphicon glyphicon-chevron-left mr-2" />
            {translate('buttonsBack')}
          </a>
        </BackContainer>
      }

      <div className="tw-flex tw-items-center tw-justify-between">
        <h5 className="!tw-m-0">
          {translate('mealLabelsEditPanelTitle')}
        </h5>
        <div className="tw-flex tw-items-center tw-gap-2">
          <button
            className="btn btn-md btn-primary !tw-bg-broccoli-600 !tw-text-white !tw-border-0"
            onClick={handleOnPrint}
          >
            {translate('buttonsPrint')}
          </button>

          <button
            className="btn btn-md btn-primary !tw-bg-broccoli-600 !tw-text-white !tw-border-0"
            onClick={handleOnDownload}
          >
            {downloading ? translate('buttonsDownloading') : translate('buttonsDownload')}
          </button>

          <button
            className="!tw-block md:!tw-hidden btn btn-md btn-primary !tw-bg-broccoli-600 !tw-text-white !tw-border-0"
            onClick={toggleSidebar}
          >
            {translate('buttonsPreview')}
          </button>
        </div>
      </div>

      <hr />

      <div className="tw-grid tw-grid-cols-12">
        <label className="tw-col-span-4 col-form-label">
          {translate('mealLabelsEditPanelFieldsTemplate')}
          <span className="label-required"> * </span>
        </label>
        <div className="tw-col-span-8 tw-flex-grow tw-items-center">
          <TemplateSelect
            id="template-select"
            value={selectedTemplate}
            options={buildTemplateOptions(labelTemplates)}
            onChange={handleOnTemplateChange}
            onDelete={handleOnDeleteLabelTemplate}
          />
        </div>
      </div>

      <div className="tw-max-h-[79vh] tw-overflow-hidden tw-overflow-y-auto tw-scrollable">
        {/* Settings */}
        <TemplateSettingsContainer
          datetime={datetime}
        />

        {/* List Items */}
        <Labels
          {
          ...{
            addLabel,
            removeLabel,
            updateLabel,
            duplicateLabel,
            labels,
            type,
          }
          }
        />
      </div>

    </Container>
  );
};

EditPanel.propTypes = {
  show: bool.isRequired,
  type: string.isRequired,
  addLabel: func.isRequired,
  duplicateLabel: func.isRequired,
  removeLabel: func.isRequired,
  updateLabel: func.isRequired,
  selectTemplate: func.isRequired,
  newTemplate: func.isRequired,
  fetchLabelTemplates: func.isRequired,
  labelTemplates: array.isRequired,
  labels: object.isRequired,
  selectedTemplate: object.isRequired,
  deleteLabelTemplate: func.isRequired,
  catererId: string,
  translate: func.isRequired,
  toggleSidebar: func.isRequired,
  userAgent: string,
};

export default withI18n(EditPanel);
