/* eslint camelcase: 0 */

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import moment from 'moment-timezone';

import loggerMiddleware from 'libs/middlewares/loggerMiddleware';
import reducers, { initialStates } from '../reducers';

export default (props) => {
  const {
    $$mealLabelState,
  } = initialStates;

  // order by meal_name, options_label
  const orderLabelByMealName = props.labels.sort((a, b) => a.meal_name.localeCompare(b.meal_name) || a.options_label.localeCompare(b.options_label)); // eslint-disable-line

  const initialState = {
    $$mealLabelStore: $$mealLabelState.merge({
      ...props,
      labels: orderLabelByMealName.map(label => ({
        ...label,
        dietaries: label.dietaries,
        allergies: label.allergies,
        meal_date: moment(label.schedule_at).format('D MMM'),
        eat_time: moment(label.schedule_at).tz(props.caterer_timezone).format('h:mm A'),
      })),
      catererName: props.caterer_name,
      catererId: props.caterer_id,
      isGroupOrder: props.is_group_order,
      type: props.type,
    }),
  };

  const reducer = combineReducers(reducers);
  const composedStore = composeWithDevTools(applyMiddleware(thunkMiddleware, loggerMiddleware));

  return composedStore(createStore)(reducer, initialState);
};
