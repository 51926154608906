/* eslint max-len: 0 */

import React from 'react';

const Duplicate = (_props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
      <path
        d="M0 3C0 1.34315 1.34315 0 3 0H8C9.65685 0 11 1.34315 11 3V3.25H9V3C9 2.44772 8.55228 2 8 2H3C2.44772 2 2 2.44772 2 3V8C2 8.55228 2.44772 9 3 9V11C1.34315 11 0 9.65685 0 8V3Z"
        fill="#141415"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8C5 6.34315 6.34315 5 8 5H13C14.6569 5 16 6.34315 16 8V13C16 14.6569 14.6569 16 13 16H8C6.34315 16 5 14.6569 5 13V8ZM8 7C7.44772 7 7 7.44772 7 8V13C7 13.5523 7.44772 14 8 14H13C13.5523 14 14 13.5523 14 13V8C14 7.44772 13.5523 7 13 7H8Z"
        fill="#141415"
      />
    </g>
  </svg>
);

export default Duplicate;
