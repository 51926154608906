/* eslint max-len: 0, no-global-assign: 0, no-restricted-globals: 0 */

import React from 'react';
import styled from 'styled-components';
import { object, func, string } from 'prop-types';

import { withI18n } from 'libs/support/i18n';
import PlusIcon from 'libs/components/Icons/Plus';
import ListItem from './ListItem';

const Link = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;

  > * {
    color: #7D9E1D;
    vertical-align: middle;
  }
`;

const Labels = ({
  type,
  addLabel,
  removeLabel,
  updateLabel,
  duplicateLabel,
  labels,
  translate,
}) => (
  <div className="tw-grid tw-grid-cols-12 tw-gap-2 tw-mt-4 tw-hidden">
    <div className="tw-col-span-12 tw-flex tw-items-center tw-justify-between">
      <h5 className="!tw-m-0">
        {translate('mealLabelsEditPanelLabelsTitle')}
      </h5>
      <Link onClick={addLabel} className="tw-flex tw-items-center tw-gap-2">
        <PlusIcon />
        <span>
          {translate('mealLabelsEditPanelLabelsButtonsNew')}
        </span>
      </Link>
    </div>
    <div className="tw-col-span-12 tw-mt-4">
      <ul className="list-group list-group-flush !tw-p-0 tw-flex tw-flex-col tw-gap-2">
        {
          labels.map((label, index) => (
            <ListItem
              {
              ...{
                key: `label-${label.get('id')}`,
                index,
                label,
                duplicateLabel,
                removeLabel,
                updateLabel,
                type,
              }
              }
            />
          ))
        }
      </ul>
    </div>
  </div>
);

Labels.propTypes = {
  type: string.isRequired,
  addLabel: func.isRequired,
  duplicateLabel: func.isRequired,
  removeLabel: func.isRequired,
  updateLabel: func.isRequired,
  labels: object.isRequired,
  translate: func.isRequired,
};

export default withI18n(Labels);
