import React, { useState } from 'react';
import { array, func, object, string, bool } from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

import EditPanel from './EditPanel';
import LeftPanel from './LeftPanel';

const Container = styled.div`
  > section {
    background: transparent !important;
  }
`;

const MealLabel = ({
  type,
  addLabel,
  defaultLabels,
  duplicateLabel,
  removeLabel,
  updateLabel,
  selectTemplate,
  newTemplate,
  deleteLabelTemplate,
  fetchLabelTemplates,
  labelTemplates,
  mealPlanId,
  selectedTemplate,
  onlySheet,
  catererId,
}) => {
  const [labels] = useState(defaultLabels.toJS());

  return (
    <Container className={classNames('flex-container')}>
      <LeftPanel
        {...{
          labels,
          type,
          catererId,
          template: selectedTemplate,
        }}
      />

      {
        !onlySheet &&
          <EditPanel
            {
              ...{
                type,
                addLabel,
                duplicateLabel,
                removeLabel,
                updateLabel,
                selectTemplate,
                newTemplate,
                deleteLabelTemplate,
                fetchLabelTemplates,
                labelTemplates,
                labels,
                mealPlanId,
                selectedTemplate,
                catererId,
              }
            }
          />
      }
    </Container>
  );
};

MealLabel.propTypes = {
  type: string.isRequired,
  addLabel: func.isRequired,
  duplicateLabel: func.isRequired,
  removeLabel: func.isRequired,
  updateLabel: func.isRequired,
  selectTemplate: func.isRequired,
  fetchLabelTemplates: func.isRequired,
  deleteLabelTemplate: func.isRequired,
  newTemplate: func.isRequired,
  labelTemplates: array.isRequired,
  defaultLabels: object.isRequired,
  selectedTemplate: object.isRequired,
  mealPlanId: string,
  catererId: string.isRequired,
  onlySheet: bool,
};

export default MealLabel;
