/* eslint max-len: 0 */

import React, { memo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { bool, func, object, string } from 'prop-types';
import { withAppContext } from 'libs/support/appContext';

import * as mealLabelActions from '../actions/mealLabelActions';
import TemplateSettingsV1 from '../components/v1/TemplateSettings';
import TemplateSettingsV2 from '../components/v2/TemplateSettings';

const TemplateSettingsContainer = withAppContext(({
  isEditTemplateMode,
  mealPlanId,
  saveLabelTemplate,
  setEditTemplateMode,
  successMsg,
  type = 'default',
  template,
  updateTempTemplate,
  updateDateTimeLabels,
  datetime,
  flagIsEnabled,
}) => {
  const Component = flagIsEnabled('meal_label_v2_enabled') ? TemplateSettingsV2 : TemplateSettingsV1;

  return (
    <Component
      {
      ...{
        isEditTemplateMode,
        mealPlanId,
        saveLabelTemplate,
        setEditTemplateMode,
        successMsg,
        type,
        template,
        updateTempTemplate,
        updateDateTimeLabels,
        datetime,
      }
    }
    />
  );
});

TemplateSettingsContainer.propTypes = {
  isEditTemplateMode: bool.isRequired,
  mealPlanId: string,
  saveLabelTemplate: func.isRequired,
  setEditTemplateMode: func.isRequired,
  type: string,
  successMsg: string,
  template: object.isRequired,
  updateTempTemplate: func.isRequired,
  flagIsEnabled: func,
  datetime: object,
};

const mapStateToProps = ({
  $$mealLabelStore,
}) => ({
  isEditTemplateMode: $$mealLabelStore.get('isEditTemplateMode'),
  mealPlanId: $$mealLabelStore.get('meal_plan_id'),
  template: $$mealLabelStore.get('selectedTemplate').toJS(),
  type: $$mealLabelStore.get('type'),
  successMsg: $$mealLabelStore.get('successMsg'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...mealLabelActions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(memo(TemplateSettingsContainer));
