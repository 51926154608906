/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint max-len: 0, no-global-assign: 0, no-restricted-globals: 0, no-param-reassign: 0  */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { bool, func, object, string } from 'prop-types';

import { withI18n } from 'libs/support/i18n';
import FieldComponent from 'libs/components/FieldComponent';
import { blankish, notBlank } from 'libs/support/string';

const PAGES = [{ value: 'A4', label: 'A4' }];
const Container = styled.div`
  padding: 0;
`;

const Link = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;

  > * {
    color: #7D9E1D;
    vertical-align: middle;
  }
`;

const TemplateSettings = ({
  isEditTemplateMode,
  mealPlanId,
  saveLabelTemplate,
  setEditTemplateMode,
  successMsg,
  template,
  updateTempTemplate,
  translate,
}) => {
  let form;

  const sortOptions = [
    { label: 'Meal Name', value: 'meal_name' },
    { label: 'User Name', value: 'name' },
  ];

  const [_, setSortOptionSelected] = useState(sortOptions[0]);

  const handleOnSave = () => {
    const params = new FormData(form);
    saveLabelTemplate(mealPlanId, template.uuid, params);
  };

  useEffect(() => {
    const selected = sortOptions.find(s => s.value === template.settings.sort_by);
    setSortOptionSelected(selected);
  }, [template]);

  const renderEditModeActions = () => (
    <div className="tw-flex tw-justify-end tw-mt-2">
      {
        !template.new &&
          <button className="btn btn-sm btn-light" onClick={_ => setEditTemplateMode(false)}>
            { translate('buttonsCancel') }
          </button>
      }

      <button className="btn btn-sm btn-primary" onClick={handleOnSave}>
        { translate('buttonsSave') }
      </button>
    </div>
  );

  const renderNonEditModeActions = () => {
    if (blankish(template.uuid)) return null;

    return (
      <Link onClick={_ => setEditTemplateMode(true)}>
        { translate('mealLabelsEditPanelTemplateButtonsEdit') }
      </Link>
    );
  };

  return (
    <Container id="template-settings">
      {
        isEditTemplateMode ? renderEditModeActions() : renderNonEditModeActions()
      }
      <hr />

      <div className="tw-grid tw-grid-cols-12 tw-gap-2">
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
          <h5 className="align-middle">
            {translate('mealLabelsEditPanelTemplateTitle')}
          </h5>
        </div>
      </div>

      {
        notBlank(successMsg) &&
          <div className="alert alert-primary" role="alert">
            <div className="text-center" style={{ color: '#141415' }}>
              { successMsg }
            </div>
          </div>
      }

      <form ref={(node) => { form = node; }}>
        <div className="tw-flex tw-flex-col tw-gap-2">
          <FieldComponent
            key={`template-name-${template.value}`}
            isRequired
            label="mealLabelsEditPanelTemplateFieldsName"
            type="text"
            name="label_template[name]"
            defaultValue={template.name}
            disabled={!isEditTemplateMode}
            fieldClassName="tw-grid tw-grid-cols-12"
            labelClassName="tw-col-span-4 col-form-label"
            inputClassName="tw-col-span-8"
          />
          <FieldComponent
            label="mealLabelsEditPanelTemplateFieldsPageSize"
            type="react-select"
            name="label_template[settings][page_size]"
            fieldClassName="tw-grid tw-grid-cols-12"
            labelClassName="tw-col-span-4 col-form-label"
            inputClassName="tw-col-span-8"
            value={{ value: template.settings.page_size, label: template.settings.page_size }}
            disabled={!isEditTemplateMode}
            onChange={target => updateTempTemplate({ page_size: target.value })}
            options={PAGES}
          />

          <div className="tw-grid tw-grid-cols-12">
            <label className="tw-col-span-4 col-form-label">
              {translate('mealLabelsEditPanelTemplateFieldsSheetMargin')}
            </label>
            <div className="tw-col-span-8">
              <FieldComponent
                label="mealLabelsEditPanelTemplateFieldsMarginTopBottom"
                type="text"
                name="label_template[settings][sheet_margin_top_bottom]"
                value={template.settings.sheet_margin_top_bottom}
                onChange={e => updateTempTemplate({
                  sheet_margin_top_bottom: e.target.value,
                })}
                disabled={!isEditTemplateMode}
                labelClassName="col-sm-6 col-form-label"
                inputClassName="col-sm-6"
              />
              <FieldComponent
                label="mealLabelsEditPanelTemplateFieldsMarginLeftRight"
                type="text"
                name="label_template[settings][sheet_margin_left_right]"
                value={template.settings.sheet_margin_left_right}
                onChange={e => updateTempTemplate({
                  sheet_margin_left_right: e.target.value,
                })}
                disabled={!isEditTemplateMode}
                labelClassName="col-sm-6 col-form-label"
                inputClassName="col-sm-6"
              />
            </div>
          </div>

          <div className="tw-grid tw-grid-cols-12">
            <label className="tw-col-span-4 col-form-label">
              {translate('mealLabelsEditPanelTemplateFieldsLabelMargin')}
            </label>
            <div className="tw-col-span-8">
              <FieldComponent
                label="mealLabelsEditPanelTemplateFieldsMarginTopBottom"
                type="text"
                name="label_template[settings][label_margin_top_bottom]"
                value={template.settings.label_margin_top_bottom}
                onChange={e => updateTempTemplate({
                  label_margin_top_bottom: e.target.value,
                })}
                disabled={!isEditTemplateMode}
                labelClassName="col-sm-6 col-form-label"
                inputClassName="col-sm-6"
              />
              <FieldComponent
                label="mealLabelsEditPanelTemplateFieldsMarginLeftRight"
                type="text"
                name="label_template[settings][label_margin_left_right]"
                value={template.settings.label_margin_left_right}
                onChange={e => updateTempTemplate({
                  label_margin_left_right: e.target.value,
                })}
                disabled={!isEditTemplateMode}
                labelClassName="col-sm-6 col-form-label"
                inputClassName="col-sm-6"
              />
            </div>
          </div>

          <hr className="!tw-my-2" />

          <FieldComponent
            label="mealLabelsEditPanelTemplateFieldsLabelWidth"
            type="text"
            name="label_template[settings][label_width]"
            value={template.settings.label_width}
            onChange={e => updateTempTemplate({ label_width: e.target.value })}
            disabled={!isEditTemplateMode}
            fieldClassName="tw-grid tw-grid-cols-12"
            labelClassName="tw-col-span-4 col-form-label"
            inputClassName="tw-col-span-8 tw-text-right"
          />

          <FieldComponent
            label="mealLabelsEditPanelTemplateFieldsLabelHeight"
            type="text"
            name="label_template[settings][label_height]"
            value={template.settings.label_height}
            onChange={e => updateTempTemplate({ label_heigh: e.target.value })}
            disabled={!isEditTemplateMode}
            fieldClassName="tw-grid tw-grid-cols-12"
            labelClassName="tw-col-span-4 col-form-label"
            inputClassName="tw-col-span-8 tw-text-right"
          />

          <FieldComponent
            label="mealLabelsEditPanelTemplateFieldsNumberAcross"
            type="number"
            name="label_template[settings][label_per_row]"
            value={template.settings.label_per_row}
            onChange={e => updateTempTemplate({ label_per_row: parseInt(e.target.value, 10) })}
            disabled={!isEditTemplateMode}
            fieldClassName="tw-grid tw-grid-cols-12"
            labelClassName="tw-col-span-4 col-form-label"
            inputClassName="tw-col-span-8 tw-text-right"
          />

          <FieldComponent
            label="mealLabelsEditPanelTemplateFieldsNumberDown"
            type="number"
            name="label_template[settings][label_per_col]"
            value={template.settings.label_per_col}
            onChange={e => updateTempTemplate({ label_per_col: parseInt(e.target.value, 10) })}
            disabled={!isEditTemplateMode}
            fieldClassName="tw-grid tw-grid-cols-12"
            labelClassName="tw-col-span-4 col-form-label"
            inputClassName="tw-col-span-8 tw-text-right"
          />
        </div>
      </form>
    </Container>
  );
};

TemplateSettings.propTypes = {
  isEditTemplateMode: bool,
  mealPlanId: string,
  saveLabelTemplate: func.isRequired,
  setEditTemplateMode: func.isRequired,
  successMsg: string,
  template: object.isRequired,
  updateTempTemplate: func.isRequired,
  translate: func.isRequired,
};

TemplateSettings.defaultProps = {
  isEditTemplateMode: true,
};

export default withI18n(TemplateSettings);
