/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint max-len: 0 */

import React, { Fragment } from 'react';
import Intl from 'intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { withAppContext } from 'libs/support/appContext';
import { array, func, object, string, bool } from 'prop-types';

import { translations } from 'libs/i18n/translations';
import { defaultLocale } from 'libs/i18n/default';

import * as mealLabelActions from '../actions/mealLabelActions';

import MealLabelV1 from '../components/v1/Index';
import MealLabelV2 from '../components/v2/Index';


global.Intl = Intl;

const MealLabelContainer = withAppContext(({
  addLabel,
  duplicateLabel,
  removeLabel,
  updateLabel,
  selectTemplate,
  newTemplate,
  deleteLabelTemplate,
  fetchLabelTemplates,
  labelTemplates,
  labels,
  mealPlanId,
  selectedTemplate,
  onlySheet,
  catererName,
  catererId,
  type,
  flagIsEnabled,
}) => {
  const locale = defaultLocale;
  const messages = translations[locale];
  const Component = flagIsEnabled('meal_label_v2_enabled') ? MealLabelV2 : MealLabelV1;

  const defaultLabels = labels.filter(e => e.get('vendor_name') === catererName);

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <Component
        {
        ...{
          type,
          addLabel,
          duplicateLabel,
          removeLabel,
          updateLabel,
          selectTemplate,
          newTemplate,
          deleteLabelTemplate,
          fetchLabelTemplates,
          labelTemplates,
          mealPlanId,
          selectedTemplate,
          defaultLabels,
          onlySheet,
          catererId,
        }}
      />
    </IntlProvider>
  );
});

MealLabelContainer.propTypes = {
  addLabel: func.isRequired,
  duplicateLabel: func.isRequired,
  removeLabel: func.isRequired,
  updateLabel: func.isRequired,
  selectTemplate: func.isRequired,
  fetchLabelTemplates: func.isRequired,
  deleteLabelTemplate: func.isRequired,
  newTemplate: func.isRequired,
  labelTemplates: array.isRequired,
  labels: object.isRequired,
  selectedTemplate: object.isRequired,
  mealPlanId: string,
  onlySheet: bool,
  catererName: string,
  catererId: string,
  type: string,
  isGroupOrder: bool,
};

const mapStateToProps = ({
  $$mealLabelStore,
}) => ({
  labels: $$mealLabelStore.get('labels'),
  selectedTemplate: $$mealLabelStore.get('selectedTemplate').toJS(),
  labelTemplates: $$mealLabelStore.get('labelTemplates').toJS(),
  mealPlanId: $$mealLabelStore.get('meal_plan_id'),
  successMsg: $$mealLabelStore.get('successMsg'),
  catererName: $$mealLabelStore.get('catererName'),
  catererId: $$mealLabelStore.get('catererId'),
  isGroupOrder: $$mealLabelStore.get('isGroupOrder'),
  type: $$mealLabelStore.get('type'),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...mealLabelActions,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)((MealLabelContainer));
