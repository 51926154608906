/* eslint max-len: 0, no-global-assign: 0, no-restricted-globals: 0 */
/* eslint-disable react/no-array-index-key */

import React, { useState, Fragment, useEffect } from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import { func, number, object, string } from 'prop-types';

import { withI18n } from 'libs/support/i18n';
import { dietaryIconsMap, allergyIconsMap } from '@caterspot/react-label-sheet';
import { DuplicateIcon, EditIcon, TrashIcon } from 'libs/components/Icons';
import { DIETARIES, ALLERGIES } from 'bundles/meal_labels/constants/mealLabelAttributes';
import Select from 'react-select';
import EditableField from './EditableField';

const buttonCss = css`
  box-sizing: border-box;
  width: auto;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  ${buttonCss}
  background: ${props => props.theme.colors.white};
`;

const ButtonRed = styled.button`
  ${buttonCss}
  background: ${props => props.theme.colors.deepChestNut};
  border-color: ${props => props.theme.colors.deepChestNut};
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-family: ${props => props.theme.fonts.proxima};
`;

const Subtext = styled.p`
  font-size: 13px;
  line-height: 24px;
  font-family: ${props => props.theme.fonts.proxima};
  margin: 0 !important;
`;

const DietariesIcons = styled.div`
  > svg {
    transform: scaleX(.75) scaleY(.75);
    margin-left: -0.5rem;
  }
`;

/**
 * ListItem
 */

const ListItem = ({
  type,
  index,
  label,
  updateLabel,
  removeLabel,
  duplicateLabel,
  translate,
}) => {
  const [attrs, setAttrs] = useState();
  const [isEditing, setIsEditing] = useState(false);

  const dietariesOptions = DIETARIES;
  const allergiesOptions = ALLERGIES;

  const handleOnSave = (idx) => {
    updateLabel(idx, attrs);
    setIsEditing(false);
  };

  const handleOnCancel = () => {
    setAttrs(label.toJS());
    setIsEditing(false);
  };

  const handleOnChange = (newAttrs) => {
    setAttrs(prevAttrs => ({ ...prevAttrs, ...newAttrs }));
  };

  useEffect(() => {
    setAttrs(label.toJS());
  }, [label]);

  if (!attrs) return <div />;

  return (
    <li className="list-group-items tw-flex !tw-p-4 !tw-border !tw-border-solid !tw-border-gray-200">
      <div className="tw-grid tw-grid-cols-12 tw-w-full">
        <div className={classNames('tw-relative tw-flex tw-flex-col', {
          'tw-col-span-11': !isEditing,
          'tw-col-span-10': isEditing,
        })}
        >
          {type === 'default' &&
            <EditableField
              value={attrs.name}
              isEditing={isEditing}
              field="name"
              fieldLabel={`${translate('mealLabelsEditPanelLabelsTermsFor')}: `}
              onChange={handleOnChange}
              placeholder={translate('mealLabelsEditPanelLabelsPlaceholdersFor')}
              tag={Subtext}
            />
          }
          <EditableField
            value={attrs.meal_name}
            field="meal_name"
            isEditing={isEditing}
            onChange={handleOnChange}
            placeholder={translate('mealLabelsEditPanelLabelsPlaceholdersMenu')}
            tag="h6"
          />
          <EditableField
            value={attrs.options_label}
            field="options_label"
            isEditing={isEditing}
            onChange={handleOnChange}
            placeholder={translate('mealLabelsEditPanelLabelsPlaceholdersOptions')}
            tagClassName="tw-flex-1"
            tag={Text}
          />

          {
            isEditing ?
              <Fragment>
                <div className="tw-mb-2">
                  <Select
                    className="dietaries-select"
                    defaultValue={attrs.dietaries && dietariesOptions.filter(v => attrs.dietaries.includes(v.value))}
                    options={dietariesOptions}
                    onChange={e => handleOnChange({ dietaries: e.map(t => t.value) })}
                    isMulti
                  />
                </div>
                <div className="tw-mb-2">
                  <Select
                    className="allergies-select"
                    defaultValue={attrs.allergies && allergiesOptions.filter(v => attrs.allergies.includes(v.value))}
                    options={allergiesOptions}
                    onChange={e => handleOnChange({ allergies: e.map(t => t.value) })}
                    isMulti
                  />
                </div>
                <div className="tw-grid tw-grid-cols-12 tw-gap-1">
                  <div className="tw-col-span-6">
                    <input
                      type="text"
                      className="form-control !tw-p-2"
                      name="vendor_name"
                      value={attrs.vendor_name}
                      placeholder={translate('mealLabelsEditPanelLabelsPlaceholdersVendor')}
                      onChange={e => handleOnChange({ vendor_name: e.target.value })}
                    />
                  </div>
                  <div className="tw-col-span-3">
                    <input
                      type="text"
                      className="form-control !tw-p-2"
                      name="meal_date"
                      value={attrs.meal_date}
                      placeholder={translate('mealLabelsEditPanelLabelsPlaceholdersDate')}
                      onChange={e => handleOnChange({ meal_date: e.target.value })}
                    />
                  </div>
                  <div className="tw-col-span-3">
                    <input
                      type="text"
                      className="form-control !tw-p-2"
                      name="eat_time"
                      value={attrs.eat_time}
                      placeholder={translate('mealLabelsEditPanelLabelsPlaceholdersEatBy')}
                      onChange={e => handleOnChange({ eat_time: e.target.value })}
                    />
                  </div>
                </div>
              </Fragment> :
              <Fragment>
                {label &&
                  (
                    <DietariesIcons className="tw-flex tw-justify-start tw-gap-1">
                      {
                        (attrs.dietaries || label.get('dietaries') || []).map((icon, key) => {
                          const Tag = dietaryIconsMap[icon];
                          return <Tag width="10mm" height="10mm" key={key} />;
                        })
                      }
                      {
                        (attrs.allergies || label.get('allergies') || []).map((icon, key) => {
                          const Tag = allergyIconsMap[icon];
                          return <Tag width="10mm" height="10mm" key={key} />;
                        })
                      }
                    </DietariesIcons>
                  )
                }
                <div className="tw-flex tw-flex-col">
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <span className="tw-m-0 !tw-text-xs">
                      {attrs.meal_date }
                    </span>
                    <span>|</span>
                    <span className="tw-m-0 !tw-text-xs">
                      { `${translate('mealLabelsEditPanelLabelsTermsEatBy')}: ${attrs.eat_time}` }
                    </span>
                  </div>
                  <span className="!tw-text-xs">
                    { `${translate('mealLabelsEditPanelLabelsTermsFrom')}: ${attrs.vendor_name}` }
                  </span>
                </div>
              </Fragment>
          }
        </div>
        <div className={classNames('tw-my-auto tw-text-right tw-h-full', {
          'tw-col-span-1': !isEditing,
          'tw-col-span-2': isEditing,
        })}
        >
          {
            isEditing ?
              <div className="tw-flex tw-flex-col tw-gap-2 tw-items-end">
                <button
                  className="!tw-text-xs !tw-w-14 !tw-h-8 !tw-bg-gray-100 !tw-text-gray-900 !tw-rounded"
                  onClick={handleOnCancel}
                >
                  { translate('mealLabelsEditPanelLabelsButtonsCancel') }
                </button>
                <button
                  className="!tw-text-xs !tw-w-14 !tw-h-8 !tw-bg-broccoli-600 !tw-text-white !tw-rounded"
                  onClick={_ => handleOnSave(attrs.id)}
                >
                  { translate('mealLabelsEditPanelLabelsButtonsSave') }
                </button>
              </div> :
              <div className={classNames('tw-rounded tw-border tw-border-gray-200 tw-overflow-hidden', {
                'tw-grid tw-grid-rows-3 tw-divide-y': true,
              })}
              >
                <Button onClick={_ => duplicateLabel(index)} id={`duplicate-label-${label.id}`}>
                  <DuplicateIcon />
                </Button>
                <Button onClick={_ => setIsEditing(true)} id={`edit-label-${label.id}`}>
                  <EditIcon />
                </Button>
                <ButtonRed className="" onClick={_ => removeLabel(index)} id={`delete-label-${label.id}`}>
                  <TrashIcon color="#ffffff" opacity="1" />
                </ButtonRed>
              </div>
          }
        </div>
      </div>
    </li>
  );
};

ListItem.propTypes = {
  type: string.isRequired,
  index: number.isRequired,
  label: object.isRequired,
  updateLabel: func.isRequired,
  removeLabel: func.isRequired,
  duplicateLabel: func.isRequired,
  translate: func.isRequired,
};

export default withI18n(ListItem);
