/* eslint max-len: 0, no-global-assign: 0, no-restricted-globals: 0 */

import React from 'react';
import { notBlank } from 'libs/support/string';
import { any, bool, func, string } from 'prop-types';

/**
 * EditableField
 */

const EditableField = ({
  field,
  fieldLabel,
  isEditing,
  inputClassName,
  onChange,
  placeholder,
  tag,
  tagClassName,
  value,
}) => {
  if (isEditing) {
    return (
      <div className={inputClassName}>
        <input
          type="text"
          className="form-control !tw-p-2 !tw-mb-2"
          name={field}
          value={value}
          placeholder={placeholder}
          onChange={e => onChange({ [field]: e.target.value })}
        />
      </div>
    );
  }
  const Tag = tag;
  return (
    <Tag className={tagClassName}>
      { notBlank(fieldLabel) && fieldLabel }
      { value }
    </Tag>
  );
};

EditableField.propTypes = {
  field: string.isRequired,
  fieldLabel: string,
  isEditing: bool.isRequired,
  inputClassName: string,
  onChange: func.isRequired,
  placeholder: string,
  tag: any.isRequired,
  tagClassName: string,
  value: string,
};

EditableField.defaultProps = {
  fieldLabel: null,
  inputClassName: '',
  placeholder: '',
  tagClassName: '',
};

export default EditableField;
