export const DIETARIES = [
  { label: 'Vegetarian', value: 'Vegetarian' },
  { label: 'Vegan', value: 'Vegan' },
  { label: 'Halal', value: 'Halal' },
  { label: 'Gluten Free', value: 'Gluten Free' },
];

export const ALLERGIES = [
  { label: 'Beef', value: 'Beef' },
  { label: 'Celery', value: 'Celery' },
  { label: 'Crustaceans', value: 'Crustaceans' },
  { label: 'Egg', value: 'Egg' },
  { label: 'Fish', value: 'Fish' },
  { label: 'Lupin', value: 'Lupin' },
  { label: 'Milk', value: 'Milk' },
  { label: 'Molluscs', value: 'Molluscs' },
  { label: 'Mustard', value: 'Mustard' },
  { label: 'Peanut', value: 'Peanut' },
  { label: 'Pork', value: 'Pork' },
  { label: 'Sesame', value: 'Sesame' },
  { label: 'Soya', value: 'Soya' },
  { label: 'Sulphurdioxide', value: 'Sulphurdioxide' },
  { label: 'Treenut', value: 'Treenut' },
  { label: 'Wheat', value: 'Wheat' },
];
