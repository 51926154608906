/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable quotes */
import React, { Fragment } from "react";
import { array, bool, func, string } from 'prop-types';
import moment from 'moment';
import uppercamelcase from 'uppercamelcase';
import { withI18n } from 'libs/support/i18n';
import SortChevron from 'libs/components/Icons/v2/SortChevron';
import classNames from 'classnames';
import { trackSortState } from "packs/events";
import { formattedPrice } from 'libs/support/price';
import { withAppContext } from 'libs/support/appContext';

const DesktopOrderList = ({
  orders,
  isFetching,
  translate,
  sort,
  setSort,
  redirectToOrderDetails,
  enableSort = true,
  flagIsEnabled,
}) => {
  const isKeepSampleEnabled = flagIsEnabled('food_safety_enabled');

  const columnSkeleton = (style) => (
    <div className="skeleton" style={style}>
      <div className="line" style={{ width: '100px' }} />
    </div>
  );

  const getSortValue = (sortKey) => {
    const sortValue = sort?.replace(`${sortKey} `, '');
    if (sortValue === 'asc') { return 'desc'; }
    return 'asc';
  };

  const iconSort = (sortKey) => {
    if (sortKey === 'asc') { return <SortChevron />; }
    return <SortChevron />;
  };

  const setSortUrl = (item) => {
    const sortValue = getSortValue(item);
    trackSortState(`${item} ${sortValue}`);
    setSort(`${item} ${sortValue}`);
  };

  const getSortedLabel = (key) => {
    if (enableSort) {
      return (
        <a
          onClick={() => setSortUrl(key)}
        >
          <span
            className={classNames('', {
              'sort-active': sort?.includes(key),
            })}
          >
            {translate(`ordersTableHeading${uppercamelcase(key)}`)}
            <span className="sort-icon">
              {iconSort(getSortValue(key))}
            </span>
          </span>
        </a>
      );
    }
    return translate(`ordersTableHeading${uppercamelcase(key)}`);
  };

  return (
    <Fragment>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>
                {translate('ordersTableHeadingOrderId')}
              </th>
              <th>
                {getSortedLabel('status')}
              </th>
              <th className="total">
                {getSortedLabel('total_amount')}
              </th>
              <th className="delivery-type">
                {getSortedLabel('delivery_type')}
              </th>
              <th className="delivery-date">
                {getSortedLabel('deliver_at')}
              </th>
              <th className="pickup-time">
                {translate('ordersTableHeadingPickupTime')}
              </th>
              <th className="delivery-time">
                {translate('ordersTableHeadingDeliveryTime')}
              </th>
              <th className="outlet">
                {getSortedLabel('outlet_id')}
              </th>
              <th className="delivery-address">
                {translate('ordersTableHeadingDeliveryAddress')}
              </th>
              <th className="customer">
                {getSortedLabel('first_name')}
              </th>
              <th className="date">
                {getSortedLabel('created_at')}
              </th>
              { isKeepSampleEnabled &&
                <th className="keep-samples-until">
                  {getSortedLabel('keep_sample_until')}
                </th>
              }
            </tr>
          </thead>
          <tbody>
            {isFetching ? (
              <Fragment>
                {[...Array(3)].map((_a, i) => (
                  <tr key={i}>
                    <td>
                      {
                        columnSkeleton({
                          justifyContent: 'initial',
                          alignItems: 'initial',
                        })
                      }
                    </td>
                    {
                      [...Array(6)].map((_b, j) => (
                        <td key={j}>{ columnSkeleton() }</td>
                      ))
                    }
                  </tr>
                ))}
              </Fragment>
            ) :
            orders?.map((order) => (
              <tr
                className={classNames({
                  draft: ['assign_outlet', 'draft', 'pending'].includes(order.status),
                })}
                onClick={() => redirectToOrderDetails(order.uuid, order.klassName)}
              >
                <td>
                  <a href="#">#{order.code}</a>
                </td>
                <td>
                  {order.status &&
                    <span
                      className={`chip ${order.status}`}
                    >
                      {translate(`ordersFiltersStates${uppercamelcase(order.status)}`)}
                    </span>
                  }
                </td>
                <td>{formattedPrice(order.vTotalAmount)}</td>
                <td>
                  {translate(`ordersShowFormsDeliveryTypeOptionsV2${uppercamelcase(order.deliveryType)}`)}
                </td>
                <td>
                  { moment(order.deliverAt).utc().format('ddd D MMM YYYY') }
                </td>
                <td>
                  { order.deliveryType === 'caterspot' &&
                    order.pickupAt ? moment(order.pickupAt).utc().format('h:mm A') : '' }
                </td>
                <td>
                  { moment(order.deliverAt).utc().format('h:mm A') }
                </td>
                <td>
                  { order?.outlet?.name }
                </td>
                <td>
                  { (order.deliveryType === 'caterspot' || order.status === 'draft') ?
                    translate('ordersTableBodyDeliveryByCaterspot')
                    :
                    `${order?.address?.unitAddress} ${order?.address?.streetAddress}`
                  }
                </td>
                <td>
                  { (order.deliveryType !== 'caterspot' && order.status !== 'draft') &&
                    <Fragment>
                      {order.firstName} {order.lastName}
                    </Fragment>
                  }
                </td>
                <td>
                  { moment(order.createdAt).utc().format('ddd D MMM YYYY') }
                </td>
                { isKeepSampleEnabled &&
                  <td>
                    {order.keepSampleUntil ? moment.tz(order.keepSampleUntil, 'Asia/Singapore').format('ddd D MMM YYYY') : ''}
                  </td>
                }
              </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

DesktopOrderList.propTypes = {
  orders: array,
  isFetching: bool,
  translate: func,
  sort: string,
  setSort: func,
  redirectToOrderDetails: func,
  enableSort: bool,
  flagIsEnabled: func,
};

export default withAppContext(withI18n(DesktopOrderList));
